import { ru } from 'vuetify/src/locale'
import { request } from '@/utils/request'

export default async () => ({
  $vuetify: {
    ...ru
  },
  ...await request('/assets/translations/ru.json'),

  attempts_left_total: {
    one: 'Осталось {count} из {all} {unit} на весь период обучения'
  },

  attempts_left_today: {
    one: 'Осталось {count} из {all} {unit} на сегодня'
  },

  available: {
    one: 'Доступно'
  },

  points_count_of: {
    one: 'Набрано {count} из {all} необходимых баллов'
  },

  positive_score_of: {
    one: 'Проходной балл, не менее: {count} из {all}'
  },

  $app: {
    loading: 'Загрузка...',
    expand_list_of_lessons: 'Раскрыть список уроков',
    no_lessons: 'Уроков нет',
    exit: 'Выход',
    no_data: 'Нет данных',
    upload_photo: 'Загрузить фото',
    delete_photo: 'Удалить фото',
    page_not_found: 'Страница не найдена',
    nothing_selected: 'Ничего не выбрано',

    menuItem: {
      groups: 'Группы',
      infobase: 'Библиотека'
    },

    audio_recorder: {
      title: 'Запись аудио',
      start: 'Начать запись',
      stop: 'Остановить запись',
      progress: 'Говорите...',
      error: 'Не удалось найти микрофон'
    },

    action: {
      one: 'Действие'
    },

    ask_question: {
      one: 'Задать вопрос'
    },

    chat: {
      delete_confirm: 'Вы действительно хотите удалить этот чат?',
      select_title: 'Выберите чат или'
    },

    chat_create: {
      title: 'Новое сообщение',
      filesize_msg: 'К сообщению можно прикрепить один файл, размером не более {n} Мб',
      action_title: 'Написать новое сообщение'
    },

    date_completion: {
      one: 'Дата выполнения'
    },

    go_management: 'Перейти в режим управления заказами',

    final_lesson: {
      one: 'Итоговый (оценочный) урок'
    },

    lesson_status: {
      one: 'Статус урока'
    },

    lesson_type: {
      one: 'Тип урока'
    },

    message: {
      delete_confirm: 'Вы действительно хотите удалить это сообщение?'
    },

    md_editor: {
      label: 'Ответ (текст в формате <a href="https://ru.wikipedia.org/wiki/Markdown" target="_blank" rel="noopener nofollow noreferrer">Markdown</a>):'
    },

    meeting: {
      action: 'Перейти к мероприятию'
    },

    next: {
      one: 'Далее'
    },

    teacher_comments: 'Комментарии преподавателя',

    test_execution_time: 'Этот тест нужно пройти за',

    essay_execution_time: 'Это задание нужно выполнить за',

    documents_library: 'Библиотека документов'
  }
})
